import React, { Component } from "react";
import moment from 'moment';
import { Redirect } from "react-router-dom";
import lockr from 'lockr';
import { Player, AbstractTeamType } from './../../types';
import {
	Layout, TitleType, PlayerList, Team, Substitutes, PlayerType, Row, Col, Block,
	TeamOverview, Select, Button, Input, AbstractTeam
} from './../../components';
import { Icon, notification } from 'antd';
import { theme } from '../../styles/theme-goudenspelen';

const teamBackground = 'https://goudenspelen.s3-eu-west-1.amazonaws.com/files/goudenspelen-cycling-lineup.png';
import { startingListToPositionsList } from './../../lib/helpers'
import {NewTeamStyles} from './NewTeamStyles';

import { Element, scroller } from 'react-scroll'

export interface Props {
}

export interface State {
	redirectToPayments: boolean;
}

class NewTeamContainer extends Component<AbstractTeamType & Props, State> {
	constructor(props: AbstractTeamType & Props) {
		super(props);

		this.state = {
			redirectToPayments: false			
		};
	};

	componentDidMount() {
		this.props.activateCacheChanges();

		const cachedChanges = lockr.get('cachedNewTeamChanges') as {starting: any, bench: any, teamName: string, captainId: number, budget: number};

		if(cachedChanges) {
			this.props.initTeamState(
				cachedChanges.starting, 
				cachedChanges.bench, 
				{},
				cachedChanges.teamName, 
				cachedChanges.captainId, 
				cachedChanges.budget
			);
		}
	}

	onPlaceHolderClick = (e: any) => {
		scroller.scrollTo('all-players', {
			duration: 1000,
			delay: 100,
			smooth: true,
			offset: 50
		})
	};

	onTeamSave = (e: any) => {
		this.props.onTeamSave()
			.then(() => {

				// @ts-ignore
				window.dataLayer.push({
					event: 'team_created',
					authentication_type: 'mail',
					user_id: this.props.user.data.id
				});

				this.setState({redirectToPayments: true});
			})
			.catch(() => {
			});
	};

	render() {
		const { application, starting, bench, captainId, teamName, budget, savingTeamPending } = this.props;
		const { redirectToPayments } = this.state;
		const startingByPositions = startingListToPositionsList(starting, application.competition.lineupPositionRows);
		const totalPlayersToPick = application.competition.lineupSize + application.competition.benchSize;
		const startingPicked = starting
			.filter(player => !!player);
		const benchPicked = bench
			.filter(player => !!player);
		const totalPlayersPicked = startingPicked.length + benchPicked.length;
		const team = this.props.user && this.props.user.teams && this.props.user.teams[0];

		const deadlineDate = this.props.matches && this.props.matches.info && this.props.matches.info.deadlineDate;
		const deadlineWeek = (this.props.matches && this.props.matches.info && this.props.matches.info.deadlineWeek) || 0;
		const deadlineEpochMilliseconds = deadlineDate ? moment(deadlineDate).valueOf() : 0;

		const userRegisterInfo = (this.props.user && this.props.user.data && this.props.user.data.registerInfo) || '';

		return (
			<Layout><NewTeamStyles>
				{redirectToPayments && <Redirect
					to={{ pathname: `/my-teams/payments` }}
				/>}
				<Row>
					<Col md={16} sm={24} xs={24} className="left">
						<Block>
							<TeamOverview budget={budget}
								totalPlayers={totalPlayersToPick}
								deadlineEpochMilliseconds={deadlineEpochMilliseconds}
								deadlineWeek={deadlineWeek}
								totalPlayersSelected={totalPlayersPicked} />

							<Row style={{margin: '0 0 10px 0'}}>
								<Col md={{ span: 8, offset: 8 }} sm={24} xs={24}>
									<Input
										onChange={this.props.onTeamNameChange}
										style={{ maxWidth: 250, backgroundColor: '#fff' }}
										placeholder={'Kies een ploegnaam…'}
										value={teamName}
									/>
								</Col>

								<Col md={8} sm={24} xs={24} style={{padding: 0}}>
									<Button
										onClick={this.onTeamSave}
										type="primary"
										disabled={savingTeamPending}
										style={{ width: '100%', maxWidth: '630px' }}
										size="large">
										<Icon type="save" theme="filled" />
										Bewaar team
								</Button>
								</Col>
							</Row>

							<Team 
								widthRatio={15}
								heightRatio={13}
								clubs={this.props.clubs.data}
								bg={teamBackground}
								assetsCdn={application.competition.assetsCdn}
								selection={startingByPositions}
								playerType={PlayerType.Cycling}
								playerBadgeColor="#000"
								captainId={captainId}
								playerBadgeBgColor={theme.primaryColor}
								onPlaceholderClick={this.onPlaceHolderClick}
								showPlayerValueInsteadOfPoints={true}
								onCaptainSelect={this.props.onCaptainSelect}							
								onRemove={(player: Player) => this.props.removeStartingPlayer(player)}
								modalEnabled={true}
								playerPointsColor={"#ffffff"}
								playerPointsBgColor="#84FF00" />
							{	(this.props.application.competition.benchSize) ?
								<Substitutes
									selection={bench}
									clubs={this.props.clubs.data}
									title="De bus"
									bgColor="#F5F5F5"
									playerType={PlayerType.Cycling}
									assetsCdn={application.competition.assetsCdn}
									playerBadgeColor="#000"
									playerBadgeBgColor={"#FFD700"}
									playerPointsColor={"#ffffff"}
									modalEnabled={true}
									showPlayerValueInsteadOfPoints={true}
									playerPointsBgColor="#fff"
									onPlaceholderClick={this.onPlaceHolderClick}
									onRemove={(player: Player) => this.props.removeBenchPlayer(player)}
								/>
								: null
							}
						</Block>
					</Col>
					<Col md={8} sm={24} xs={24} className="right">
						<Block>
							<Row style={{ textAlign: 'center', margin: '0 auto 10px ' }}>
								<Col>
									<a href='https://www.hln.be/podcasts/wuyts-vlaeminck-1236' target="_blank" >
										<img style={{ maxWidth: '100%' }} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/banner_wuyts_vlaeminck.png" alt="Wuyts vs Vlaeminck"></img>
									</a>
								</Col>
							</Row>
							<Element name="all-players">
								<PlayerList
									clubs={this.props.clubs.data}
									isLoading={this.props.players.isFetching}
									hidePositions={true}
									assetsCdn={application.competition.assetsCdn}
									isPickAble={this.props.isPickAble}
									playerType={PlayerType.Cycling}
									data={this.props.players.data}
									onPick={this.props.pickPlayer}
									action
									showHeader={false}
									size={10}
								/>
							</Element>
						</Block>
					</Col>
				</Row>
			</NewTeamStyles></Layout>
		);
	}
}

const WrappedComponent = AbstractTeam(NewTeamContainer);


export default WrappedComponent;