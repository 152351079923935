import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Player, AbstractTeamType } from './../../types';
import {
	Title, TitleType, Team, Substitutes, PlayerType, Row, Col, Block,
	AbstractTeam, MatchDaySelector, Calendar, Stats
} from './../../components';
import { Icon } from 'antd';
import { theme } from '../../styles/theme-goudenspelen';

const teamBackground = 'https://goudenspelen.s3-eu-west-1.amazonaws.com/files/goudenspelen-cycling-lineup.png';
import { startingListToPositionsList } from './../../lib/helpers';
import { pick } from 'lodash';
import * as teamsActions from '../../actions/teams'

export interface Props {
	match: any;
}

export interface State {
	notFound: boolean;
	navigateToLeagues: boolean;
	selectedLeagueId: number | null;
}

class TeamContainer extends Component<AbstractTeamType & Props, State> {
	constructor(props: AbstractTeamType & Props) {
		super(props);

		this.state = {
			notFound: false,
			navigateToLeagues: false,
			selectedLeagueId: null,
		}
	};

	componentDidMount() {
		if (this.props.visibleWeekId) {
			this.getTeamInfo(this.props.visibleWeekId);
		} else if (!this.props.matches.info.deadlineWeek && this.props.matches.info.displayWeek) {
			this.getTeamInfo(this.props.matches.info.displayWeek);
		}
	}

	componentDidUpdate(prevProps: AbstractTeamType & Props) {
		if (this.props.visibleWeekId && (this.props.visibleWeekId !== prevProps.visibleWeekId)) {
			this.getTeamInfo(this.props.visibleWeekId);
		}

		if (!this.props.matches.info.deadlineWeek && this.props.matches.info.displayWeek && (this.props.matches.info.displayWeek !== prevProps.matches.info.displayWeek)) {
			this.getTeamInfo(this.props.matches.info.displayWeek);
		}
	}

	getTeamInfo = (weekId: number) => {
		const pointsWeekId = this.props.matches.info.deadlineWeek === weekId ? weekId - 1 : weekId;
		const teamId = this.props.match.params.id;
		const playerProps =
			['id', 'name', 'short', 'positionId', 'clubId', 'value', 'ban', 'injury', 'form', 'forename', 'surname'];
		const selectionProps: any[] = ['points'];

		Promise.all([teamsActions.points(teamId, pointsWeekId)])
			.then(([result]) => {
				const weekStat = result.weekStat.find((stat: any) => stat.weekId === pointsWeekId);

				const teamPointsInfo = {
					generalPoints: result.team.points !== null ? result.team.points : '-',
					generalRank: result.team.rank !== null ? result.team.rank : '-',
					visibleWeekPoints: (weekStat && weekStat.points) || '-',
					visibleWeekRank: (weekStat && weekStat.rank) || '-'
				};

				const starting = result.players
					.filter((player: any) => player.selection.positionId === 1)
					.map((player: any) => Object.assign({ inStarting: true }, pick(player, playerProps), pick(player.selection, selectionProps)));
				const bench = result.players
					.filter((player: any) => player.selection.positionId === 0)
					.map((player: any) => Object.assign({ inStarting: false }, pick(player, playerProps), pick(player.selection, selectionProps)));
				const team = result.team;
				const teamName = result.team.name;

				const captain = result.players
				.find((player: any) => player.selection.captain === 1);
				const captainId = (captain && captain.id) ? captain.id : 0;

				const budget = result.players
					.reduce((acc: any, player: any) => acc - player.value, this.props.application.competition.budget);

				this.props.loadAllMatches();
				this.props.initTeamState(starting, bench, team, teamName, captainId, budget, undefined, pointsWeekId, teamPointsInfo);
			})
			.catch(error => {
				const teamPointsInfo = {
					generalPoints: '-',
					generalRank: '-',
					visibleWeekPoints: '-',
					visibleWeekRank: '-'
				};

				this.setState({ notFound: true });
				this.props.initTeamState([], [], {}, '', null, 0, undefined, pointsWeekId, teamPointsInfo);
			})
	};

	render() {
		const {
			application, starting, bench, matches, visibleWeekId, initializedExternally,
			teamPointsInfo, captainId
		} = this.props;
		const startingByPositions = startingListToPositionsList(starting, application.competition.lineupPositionRows);

		const deadlineWeek = matches.info && matches.info.deadlineWeek;
		const displayWeek = matches.info && matches.info.displayWeek
		const match = matches.data.filter(match => match.weekId === visibleWeekId);

		const loggedInUserTeam = this.props.user && this.props.user.teams && this.props.user.teams[0];
		const teamOwner = loggedInUserTeam && (loggedInUserTeam.id === parseInt(this.props.match.params.id));
		const lastGameWeek = deadlineWeek ? deadlineWeek : (displayWeek ? displayWeek + 1 : displayWeek);

		return (
			<React.Fragment>
				{
					(visibleWeekId && lastGameWeek &&
						<Block>
							<MatchDaySelector day={visibleWeekId}
								max={lastGameWeek - 1}
								min={1}
								match={match}
								onNext={(e: any) => this.props.onDayChange('next')}
								onPrev={(e: any) => this.props.onDayChange('prev')} />
						</Block>)
					|| null
				}
				{
					(initializedExternally && <Row>
						<Col lg={24} md={24} sm={24} xs={24}>
							<Block>
								<Stats generalPoints={teamPointsInfo.generalPoints}
									generalRank={teamPointsInfo.generalRank}
									visibleWeekPoints={teamPointsInfo.visibleWeekPoints}
									visibleWeekRank={teamPointsInfo.visibleWeekRank} />
							</Block>

							<Block>
								<Team widthRatio={15}
									heightRatio={13}
									clubs={this.props.clubs.data}
									bg={teamBackground}
									captainId={captainId}
									centerAligned={true}
									selection={startingByPositions}
									assetsCdn={application.competition.assetsCdn}
									showPlayerStatsPoints={false}
									playerType={PlayerType.Cycling}
									playerBadgeColor={theme.primaryColor}
									playerBadgeBgColor={theme.primaryColor}
									playerPointsColor="#fff"
									playerPointsBgColor="#84FF00" />
								{
									(teamOwner && this.props.application.competition.benchSize &&
										<Substitutes
											selection={bench}
											clubs={this.props.clubs.data}
											title="De bus"
											bgColor="#F0F3F8"
											playerType={PlayerType.Cycling}
											assetsCdn={application.competition.assetsCdn}
											playerBadgeColor="#000"
											playerBadgeBgColor={theme.primaryColor}
											playerPointsColor="#fff"
											playerPointsBgColor="#292929"
										/>) || null
								}

								<Row style={{ textAlign: 'center', margin: '0 auto 10px ' }}>
									<Col>
										<a href='https://www.hln.be/podcasts/wuyts-vlaeminck-1236' target="_blank" >
											<img style={{ maxWidth: '25%' }} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/banner_wuyts_vlaeminck.png" alt="Wuyts vs Vlaeminck"></img>
										</a>
									</Col>
								</Row>
							</Block>
						</Col>
					</Row>) || null
				}
			</React.Fragment>
		);
	}
}

const WrappedComponent = AbstractTeam(TeamContainer);


export default WrappedComponent;